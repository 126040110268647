import { createApp } from 'vue';
import { createStore } from 'vuex';
import { createRouter, createWebHistory } from 'vue-router';

import PortalRegistration from '@/src/portal/PortalRegistration.vue';
import vuetify from '@/src/plugins/vuetify.js';
import storeConfig from '@/src/portal/vuex/registration-store.js';

document.addEventListener('DOMContentLoaded', async () => {
  const el = document.getElementById('portal-registration-vue');
  const portalData = JSON.parse(el.getAttribute('portal-data'));
  const gRecaptchaSiteKey = el.getAttribute('grecaptcha-site-key');

  const store = createStore(storeConfig);
  await store.dispatch('setPortalSettings', portalData);
  await store.dispatch('setGRecaptchaSiteKey', gRecaptchaSiteKey);

  // I need to create a router to avoid errors
  // with portal common code that expects a router
  const dummyRouter = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/portal/registrations',
        component: PortalRegistration,
      },
    ],
  });

  createApp(PortalRegistration)
    .use(store)
    .use(dummyRouter)
    .use(vuetify)
    .mount('#portal-registration-vue');
});
