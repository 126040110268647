import SnackbarStore from '@/src/common/store/snackbar-store.js';

export default {
  plugins: [],

  state: {
    portalSettings: {},
    gRecaptchaSiteKey: '',
    ...SnackbarStore.state,
  },

  mutations: {
    SET_PORTAL_SETTINGS(state, payload) {
      state.portalSettings = payload;
    },

    SET_G_RECAPTCHA_SITE_KEY(state, payload) {
      state.gRecaptchaSiteKey = payload;
    },

    ...SnackbarStore.mutations,
  },

  actions: {
    setPortalSettings(context, portalSettings) {
      context.commit('SET_PORTAL_SETTINGS', portalSettings);
    },

    setGRecaptchaSiteKey(context, gRecaptchaSiteKey) {
      context.commit('SET_G_RECAPTCHA_SITE_KEY', gRecaptchaSiteKey);
    },

    ...SnackbarStore.actions,
  },

  modules: {},

  getters: {},
};
