<template>
  <PortalLayout>
    <PortalLogin :page-announcements="portalSettings.pageAnnouncements" />
  </PortalLayout>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import PortalLogin from './views/PortalLogin.vue';
import PortalLayout from './views/PortalLayout.vue';

const store = useStore();
const portalSettings = computed(() => store.state.portalSettings);
</script>
