<template>
  <div class="tw-w-full tw-rounded tw-border tw-bg-white tw-p-4 tw-shadow-portal sm:tw-p-6">
    <div class="tw-text-xl tw-font-bold tw-leading-8">Log in to submit tickets</div>
    <v-form
      ref="form"
      class="tw-flex tw-flex-col tw-pt-4"
      @submit.prevent="onSubmit"
    >
      <VueRecaptcha
        ref="invisibleRecaptcha"
        size="invisible"
        :sitekey="gRecaptchaSiteKey"
        @verify="onVerify"
      ></VueRecaptcha>

      <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        label="Work Email"
      ></v-text-field>

      <div class="tw-mt-2 tw-flex tw-flex-row">
        <SpiceworksButton
          button-type="primary"
          text="Log in"
          type="submit"
          :loading="loading"
          :disabled="loading"
        />
      </div>
    </v-form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';
import { requestLogin } from '@/src/portal/services/registrations-service.js';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

const store = useStore();
const gRecaptchaSiteKey = computed(() => store.state.gRecaptchaSiteKey);
const showSnackbar = (payload) => store.dispatch('showSnackbar', payload);

const invisibleRecaptcha = ref(null);
const form = ref(null);
const email = ref('');
const loading = ref(false);
const v$ = useVuelidate(
  {
    email: { required, emailValidator },
  },
  { email }
);

const emailErrors = computed(() => {
  const errors = [];
  if (!v$ || !v$.value.email.$dirty) return errors;
  v$.value.email.emailValidator.$invalid && errors.push('Must be valid email.');
  v$.value.email.required.$invalid && errors.push('Email is required.');
  return errors;
});

const onVerify = async (gRecaptchaResponse) => {
  loading.value = true;

  const payload = {
    email: email.value,
    'g-recaptcha-response': gRecaptchaResponse,
  };

  const response = await requestLogin(payload);

  loading.value = false;

  const snackbarProps = {
    display: true,
    type: 'success',
    message: response.message,
  };

  if (response.errors) {
    snackbarProps.type = 'error';
    snackbarProps.message = response.errors;
  }

  showSnackbar(snackbarProps);
  invisibleRecaptcha.value.reset();
  form.value.reset();
  v$.value.$reset();
};

const onSubmit = () => {
  v$.value.$touch();
  if (!v$.value.$invalid) {
    invisibleRecaptcha.value.execute();
  }
};
</script>
